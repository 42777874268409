import React, { useState , useEffect} from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button, Tooltip } from "@mui/material";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { borderColor } from "@mui/system";
import { styled, useTheme } from "@mui/material/styles";


const LargeCards = ({ onHandleClick, imageSrc, title, description, tags,onHandleEdit, onHandleDelete }) => {
  
  const [dots,setDots] = useState(false)

  const handleDots=()=>{
    setDots(!dots)
  }

  const theme = useTheme();


  return (
    <div className="container" onClick={onHandleClick}>
      <div className="largeCard" style={{
        border:'1px solid',
        borderColor: theme.palette.background.bg,
      }}>
        <div className="imgBx">
          <img src={imageSrc} alt={`${title} Image`} />
        </div>
        <Box className="content">
          <Box display="flex" width='100%'>
            <Typography variant="h6" className="cardTextH" >
              {title}
            </Typography>
            <KeyboardArrowRightIcon className='iconCard' />

          </Box>
          <Typography variant="cardDesc" className="cardDesc" mt={1} mb={2}>
            {description}
          </Typography>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            {tags && tags.map((tag, index) => (
              <span key={index} className="badge">
                {tag}
              </span>
            ))}
          </Box>
          {/* <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            className="editDiv"
          >
            <MoreVertIcon onClick={handleDots} />

            <Box className="editBtnsGroup">
              <Tooltip title="View" placement="right">
                <Button>
                  <VisibilityIcon onClick={onHandleClick} />
                </Button>
              </Tooltip>
              <Tooltip title="Edit" placement="right">
                <Button onClick={(event) => {
                      event.stopPropagation();
                      onHandleEdit();
                    }}
                    >
                  <ModeEditIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Delete" placement="right"   
              onClick={(event) => {
                      event.stopPropagation();
                      onHandleDelete();
                    }}>
                <Button>
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Box>

          </Box>
       */}
            
        </Box>
      </div>
    </div>
  );
};

// Define prop types for better usability
LargeCards.propTypes = {
  onHandleClick: PropTypes.func,
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
};



export default LargeCards;
