import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Card,
  Typography,
  Breadcrumbs,
  Link,
  Button,
  Skeleton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SmallCards from "shared-ui/ui-component/cards/SmallCards"; // Adjust the path as needed
import SubCard from "shared-ui/ui-component/cards/SubCard";
import ConfirmDeleteModal from "shared-ui/ui-component/ConfirmDeleteModal";
import EditAppModal from "views/EditAppModal";
import api from "service/api";
import { uiStore } from "shared-ui/store";
import AddIcon from "@mui/icons-material/Add";
import mongoService from "service/mongoService";
import useAppData from "shared-ui/hooks/useAppData";

const ViewMorePage = () => {

  const { groupName, groupId } = useParams()

  const { state } = useLocation();
  const { groupTitle, desc } = state || { groupTitle: "Group" };
  const theme = useTheme();
  const navigate = useNavigate();
  const { navType } = uiStore.config;
  const {appData, loading} = useAppData(groupName, groupId)
  const apps = appData.items ?? []
  // const [apps, setApps] = useState(data.items ?? [])
  const [openModal, setOpenModal] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [appIdToDelete, setAppIdToDelete] = useState(null);
  const [appDataToEdit, setAppDataToEdit] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);

  const imageUrl = `${process.env.REACT_APP_IMAGE_DASH_URL}`;

  useEffect(() => {
    // setApps(data.items?? [])
    // const fetchApps = async () => {
    //   try {
    //     setLoading(true);
    //     const response = await mongoService.getDocuments("apps", 1, 10, '_id', 'asc', { group: groupName, group_id: groupId })
    //     setApps(response.items ?? [])
    //   } catch (error) {
    //     console.error("Error fetching apps:", error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    // fetchApps();
  }, []);

  const appName = apps.length > 0 ? groupTitle : "Application";

  const handleAppClick = async (tile) => {
    console.log(tile, "tile fetched");
    add_to_recents(tile);
    const id_fetch = tile.app_id ? tile.app_id : tile._id;

    navigate(`/apps/${id_fetch}/`, { state: { appId: id_fetch } }); // Pass _id in state
  };

  const handleClick = () => {
    navigate(`/apps/${process.env.REACT_APP_CREATE_APP_ID}/`, {
      state: { appId: process.env.REACT_APP_CREATE_APP_ID },
    }); // Pass _id in state
  };

  const add_to_recents = async (tile) => {
    const id_fetch = tile.app_id ? tile.app_id : tile._id;
    const data = {
      ...tile,
      last_opened: new Date().toISOString(),
      app_id: id_fetch,
    };
    delete data._id;
    try {
      await mongoService.createDocument("recently_opened_Apps", data);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleOpenEditModal = async (app_id) => {
    setOpenEditModal(true);
    try {
      const appData = await api.getAppDetails(app_id);
      setAppDataToEdit(appData);
    } catch (error) {
      console.error("Error fetching app details:", error);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setAppDataToEdit(null);
  };

  const handleUpdateApp = async (updatedData) => {
    handleCloseEditModal();
  };

  const handleDelete = async () => {
    if (appIdToDelete) {
      try {
        await api.deleteApp(appIdToDelete);
        console.log(`App with ID ${appIdToDelete} deleted successfully`);
      } catch (error) {
        console.error("Error deleting app:", error);
      } finally {
        handleCloseDeleteModal();
      }
    }
  };

  const handleOpenDeleteModal = (app_id) => {
    setAppIdToDelete(app_id);
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
    setAppIdToDelete(null);
  };

  return (
    <div style={{ padding: theme.spacing(0) }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2, mx: 1 }}>
        <Link href="/" color="inherit">
          Applications
        </Link>
        <Typography
          color={theme.palette.secondary.main}
        >{`${appName}`}</Typography>
      </Breadcrumbs>

      <SubCard sx={{ mb:2.5 }}>
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          // mb={1.3}
         
        >
          <Typography variant="h3" gutterBottom mb={1.5}>
            {groupTitle}
          </Typography>
          <Button
            onClick={handleClick}
            sx={{
              mb: 0,
              background: theme.palette.primary.main,
              color: theme.palette.background.default,
              border: "1px solid",
              borderColor: theme.palette.background.border,
              width: "130px",
              height: "45px",
              borderRadius: "9px",
              "&:hover": {
                background: theme.palette.secondary.main,
              },
            }}
          >
            Add New <AddIcon />
          </Button>
        </Grid>
        <Typography
          variant="h6"
          sx={{
            fontSize: "1.2em",
            fontWeight: "400",
            color: theme.palette.text.dark2,
            width: "90%",
          }}
        >
          {desc}
        </Typography>
      </SubCard>

      <Card
        className="sltdermainViewMore"
        sx={{
          background: theme.palette.background.default,
          border: "1px solid",
          borderColor: theme.palette.background.border,
        }}
      >
        <Grid container>
          {!loading && apps
            .reverse()
            .map((tile, tileIndex) => (
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2} key={tileIndex}>
                <SmallCards
                  onHandleClick={() => handleAppClick(tile)}
                  imageSrc={`${imageUrl}${navType === "light"
                    ? tile.background_image
                    : tile.background_image_dark
                    }`}
                  title={tile.title}
                  description={tile.description}
                  tags={tile.tags}
                  onHandleDelete={() => handleOpenDeleteModal(tile.app_id)}
                  onHandleEdit={() => handleOpenEditModal(tile.app_id)}
                />
                <ConfirmDeleteModal
                  open={open}
                  onClose={handleCloseDeleteModal}
                  onConfirm={handleDelete}
                />
                <EditAppModal
                  open={openEditModal}
                  onClose={handleCloseEditModal}
                  appData={appDataToEdit}
                  onUpdate={handleUpdateApp}
                />
              </Grid>
            ))}
        
        </Grid>
        {loading ? (
            <Grid container spacing={2} px={2} pb={2} mt={1}>
            {[...Array(4)].map((_, index) => (
              <Grid
                item
                xs={3}
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width={250}
                  height={120}
                />
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="40%" />
              </Grid>
            ))}
          </Grid>
        ):''}
      </Card>
    </div >
  );
};

export default ViewMorePage;
