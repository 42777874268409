import { useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

// project imports
import Header from "./Header";
import Sidebar from "./Sidebar";

import LAYOUT_CONST from "shared-ui/constant";
import { drawerWidth } from "shared-ui/store/constant";
import { uiStore } from "shared-ui/store";
import { observer } from "mobx-react";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, layout }) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...(!open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter + 200,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft:
          layout === LAYOUT_CONST.VERTICAL_LAYOUT
            ? -(drawerWidth - 72)
            : "20px",
        width: `calc(100% - ${drawerWidth}px)`,
        marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 80 : 80,
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter + 200,
      }),
      marginLeft: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? "20px" : 0,
      marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 80 : 80,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.up("md")]: {
        marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 80 : 80,
      },
    }),
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      padding: "16px",
      marginTop: 88,
      ...(!open && {
        width: `calc(100% - ${drawerWidth}px)`,
      }),
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      marginRight: "10px",
      padding: "16px",
      marginTop: 88,
      ...(!open && {
        width: `calc(100% - ${drawerWidth}px)`,
      }),
    },
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { drawerOpen, container, layout } = uiStore.config;

  const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;
  let styleBg;
  let bgBorder;

  // useEffect(()=>{
  //     styleBg = theme.palette.background.default;
  //     bgBorder = theme.palette.background.border;
  // },[])

  const { navType } = uiStore.config;

  // Load theme from localStorage on component mount
  useEffect(() => {
     const savedTheme = localStorage.getItem('themeMode');
     
     if (savedTheme && savedTheme !== navType) {
         uiStore.updateConfig('navType', savedTheme);
     }
 }, [navType]);


  const header = useMemo(
    () => (
      <Toolbar
        sx={{
          p: condition ? "10px" : "16px",
          border: ".6px solid",
          borderLeft: 0,
          background: theme.palette.background.default,
          borderColor: theme.palette.background.border,
        }}
      >
        <Header />
      </Toolbar>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layout, matchDownMd, theme, condition]
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{ bgcolor: theme.palette.background.default }}
      >
        {header}
      </AppBar>

      {/* horizontal menu-list bar */}
      {/* {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd && <HorizontalBar />} */}

      {/* drawer */}
      {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && <Sidebar />}

      {/* main content */}
      <Main
        theme={theme}
        open={drawerOpen}
        layout={layout}
        sx={{
          background: theme.palette.background.bg,
          borderRadius: 0,
        }}
      >
        <Container
          maxWidth={container ? "lg" : false}
          {...(!container && { sx: { px: { xs: 0 } } })}
        >
          <Outlet />
        </Container>
      </Main>
    </Box>
  );
};

export default observer(MainLayout);
