import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button, Tooltip, Card, Grid } from "@mui/material";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import SubCard from "./SubCard";
import { useTheme } from "@mui/material/styles";
// import image from "../../assets/images/cards/Front (1).png"

const LeftImageCard = ({
  onHandleClick,
  imageSrc,
  title,
  description,
  tags,
  onHandleEdit,
  onHandleDelete,
}) => {
  const [dots, setDots] = useState(false);

  const handleDots = () => {
    setDots(!dots);
  };
  const theme = useTheme();

  const trimDescription = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, text.lastIndexOf(" ", maxLength)) + "...";
  };

  return (
    <Box
      className="leftCard"
      onClick={onHandleClick}
      sx={{
        background: theme.palette.background.default,
        border: "1px solid",
        borderColor: theme.palette.background.border,
      }}
    >
      <div className="imgBx">
        <img src={imageSrc} alt={`${title} Image`} />
      </div>

      <Box className="content">
        <Box display="flex" alignItems="center" >
          <Typography
            variant="h6"
            className="cardTextH"
            sx={{
              color: theme.palette.text.primary,

            }}
          >
            {title}
          </Typography>
          {/* <KeyboardArrowRightIcon className="iconCard" /> */}
        </Box>
        <Typography
          variant="cardDesc"
          sx={{
            color: theme.palette.text.primary,
            mb:'.2rem',
          }}
        >
          {(description = trimDescription(description, 40))}
        </Typography>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          {tags &&
            tags.slice(-3).map((tag, index) => (
              <span
                key={index}
                className="badge"
                style={{
                  background: theme.palette.background.bg,
                  color: theme.palette.text.primary,
                }}
              >
                {tag}
              </span>
            ))}
        </Box>

        {/* <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          className="editDivs"
        >
          <MoreVertIcon
            onClick={handleDots}
            sx={{
              color: theme.palette.text.primary,
            }}
          />

          <Box className="editBtnsGroup">
            <Tooltip title="View" placement="right">
              <Button>
                <VisibilityIcon onClick={onHandleClick} />
              </Button>
            </Tooltip>
            <Tooltip title="Edit" placement="right" 
         onClick={(event) => {
          event.stopPropagation();
          onHandleEdit();
        }}
        >
              <Button>
                <ModeEditIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Delete" placement="right" 
           onClick={(event) => {
            event.stopPropagation();
            onHandleDelete();
          }}>
              <Button>
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default LeftImageCard;
