import { lazy } from 'react';

// project imports
import AuthGuard from 'shared-ui/utils/route-guard/AuthGuard';
import MainLayout from 'shared-ui/layout/MainLayout';
import Loadable from 'shared-ui/ui-component/Loadable';
import FormBuilder from 'views/form-builder';
import HomeDash from 'views/HomeDash';
import Dashboard from 'views/dashboard';
import StreamlitApp from 'views/HomeDash/StreamlitApp';
import ViewMorePage from 'views/ViewMore';
import AddAppPage from 'views/AddAppPage';


// sample page routing
const Main = Loadable(lazy(() => import('views/collections')));
const Chat = Loadable(lazy(() => import('views/chat/inference')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/collections/:collection',
            element: <Main />
        },
        {
            path: '/chat/:appType',
            element: <Chat />
        },
        {
            path: '/forms/:collection',
            element: <FormBuilder />
        },
        {
            path: '/forms/:collection/:rowId',
            element: <FormBuilder />
        },
        {
            path: '/home',
            element: <HomeDash/>
        },
        
        
        {
            path: '/dashboard/:appId', // Use this to capture appId
            element: <Dashboard /> // Ensure the same component can handle both routes
        },
        {
            path: '/apps/:appId', 
            element: <StreamlitApp /> 
        },
        {
            path: '/view-more', 
            element: <ViewMorePage /> 
        },
        {
            path: 'apps/:groupName/:groupId', 
            element: <ViewMorePage /> 
        },
        {
            path: '/add_app', 
            element: <AddAppPage/> 
        },
       
    ]
};

export default MainRoutes;
