import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Card,
  Typography,
  Breadcrumbs,
  Link,
  Button,
  Skeleton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { uiStore } from 'shared-ui/store';
import { useParams } from 'react-router-dom';
import mongoService from "service/mongoService";


const StreamlitApp = () => {
  
  const { appId } = useParams();
  const theme = useTheme();
  const [appDetails, setAppDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { navType } = uiStore.config;

  const baseURL = process.env.REACT_APP_STREAMLIT_URL; // Get the base URL from the environment variable
  const user = localStorage.getItem('user');
  const url = appId ? `${baseURL}/?appid=${appId}&theme=${navType}&user_email=${user.email}` : null; 

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 0);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

   // Fetch app details by appId
   useEffect(() => {
    const fetchAppDetails = async () => {
      try {
        setIsLoading(true);
        const response = await mongoService.getDocument('apps', appId);
        setAppDetails(response); // Set the app details in state
        setIsLoading(false);
      } catch (error) {
        setHasError(true);
        setIsLoading(false);
      }
    };

    if (appId) {
      fetchAppDetails();
    }
  }, [appId]); // Trigger the effect when appId changes



  const handleLoadError = () => {
    setHasError(true);
    setIsLoading(false);
  };
  

  return (
    <Grid container height='90vh'>
       <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2, mx: 1 }}>
        <Link href="/" color="inherit">
          Applications
        </Link>
        <Typography
          color={theme.palette.secondary.main}
        > {appDetails?.title}</Typography>
      </Breadcrumbs>

     
      {url ? (
        <div style={{ position: 'relative', width: '100%', height: '100%', borderRadius:'20px' }}>
          {isLoading && (
            <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </div>
          )}
          {/* Only render the object if not loading or has an error */}
          {!isLoading && !hasError && (
            <object
              data={url}
              type="text/html"
              width="100%"
              height="100%"
              style={{ border: 'none',borderRadius:'15px' }} // Keep the border style
              onLoad={() => setIsLoading(false)}  // Setting loading to false once loaded
              onError={handleLoadError}  // Handle loading error
            />
          )}
          {hasError && <p>Error loading the app. Please try again.</p>}
        </div>
      ) : (
        <p>Application not found</p>
      )}
    </Grid>
  );
};

export default StreamlitApp;
