// material-ui
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Divider, List, Typography, useMediaQuery } from "@mui/material";

import { Language, PhotoCameraBack } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";

// project imports
import NavItem from "./NavItem";
import menuItem from "menu-items";
import NavGroup from "./NavGroup";

import LAYOUT_CONST from "shared-ui/constant";
import { HORIZONTAL_MAX_ITEM } from "shared-ui/config";
import { uiStore } from "shared-ui/store";
import { observer } from "mobx-react";

import ButtonMenu from "./ButtonMenu";
import { useNavigate } from "react-router-dom";
import mongoService from "service/mongoService";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";

import AssessmentIcon from "@mui/icons-material/Assessment";
import EngineeringIcon from "@mui/icons-material/Engineering";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import NfcOutlinedIcon from '@mui/icons-material/NfcOutlined';
import DataThresholdingOutlinedIcon from '@mui/icons-material/DataThresholdingOutlined';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const icon = [
    DisplaySettingsIcon,
    AssessmentIcon,
    EngineeringOutlinedIcon,
    AccountTreeOutlinedIcon,
    InsertChartOutlinedIcon,
    ExtensionOutlinedIcon,
    NfcOutlinedIcon,
    DataThresholdingOutlinedIcon
  ];
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await mongoService.getDocuments("dashboard", 1, 200);
        setDashboardData(response.items ?? []);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
      }
    };

    fetchDashboardData();
  }, []);
  const theme = useTheme();
  const { drawerOpen, layout } = uiStore.config;
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [selected, setSelected] = useState(false);
  const location = useLocation();

  const [selectedButton, setSelectedButton] = useState(""); // Initialize to empty string or set default if needed

  // last menu-item to show in horizontal menu bar
  const lastItem =
    layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd
      ? HORIZONTAL_MAX_ITEM
      : null;

  let lastItemIndex = menuItem.items.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < menuItem.items.length) {
    lastItemId = menuItem.items[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    remItems = menuItem.items
      .slice(lastItem - 1, menuItem.items.length)
      .map((item) => ({
        title: item.title,
        elements: item.children,
        icon: item.icon,
        ...(item.url && {
          url: item.url,
        }),
      }));
  }
  const [selectedItem, setSelectedItem] = useState(null);
  const navItems = menuItem.items.slice(0, lastItemIndex + 1).map((item) => {
    const isSelected = selectedItem === item.id;
    switch (item.type) {
      case "group":
        if (item.url && item.id !== lastItemId) {
          return (
            <List
              key={item.id}
              {...(drawerOpen &&
                layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && {
                  sx: { mt: 1.5 },
                })}
              disablePadding={
                !drawerOpen || layout === LAYOUT_CONST.HORIZONTAL_LAYOUT
              }
              onClick={() => setSelectedItem(item.id)} // Set selected item on click
              style={{
                backgroundColor: isSelected ? "lightblue" : "transparent", // Change background color if selected
              }}
            >
              <NavItem item={item} level={1} isParents />
              {layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && (
                <Divider sx={{ py: 0.5, display: "none" }} />
              )}
            </List>
          );
        }
        return (
          <NavGroup
            key={item.id}
            item={item}
            lastItem={lastItem}
            remItems={remItems}
            lastItemId={lastItemId}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const handleClickMenu = (item) => {
    setSelectedButton(item.name); // Set selected to "group2"
    navigate(`/apps/${item.name}/${item.group_id}`, {
      state: {
        groupTitle: item.name,
        desc: item.description,
      },
    });
  };

  const handleClickApp = () => {
    setSelectedButton("group0"); // Set selected to "group2"
    navigate("/home");
  };

  return layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
    (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
    <Box {...(drawerOpen && { sx: { mt: 0.5 } })}>
      {/* {navItems}    */}

      <ButtonMenu
        icon={DashboardCustomizeOutlinedIcon} // Pass the icon component
        name="Applications" // Pass the item title
        onClick={handleClickApp}
        selected={selectedButton === "group0"} // Pass selected state
      />
      {dashboardData.map((item, index) => (
        <ButtonMenu
          key={index}
          icon={icon[index] ? icon[index] : ExtensionOutlinedIcon}
          name={item?.name}
          onClick={() => handleClickMenu(item)}
          selected={selectedButton === item.name} // Check if "group2" is selected
        />
      ))}
    </Box>
  ) : (
    <>{navItems}</>
  );
};

export default observer(MenuList);
