import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Tabs, Tab } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SubCard from "shared-ui/ui-component/cards/SubCard";
import TableChartIcon from "@mui/icons-material/TableChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import api from "service/api";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { uiStore } from 'shared-ui/store';

const DataVisualization = ({ prompt, index, appId, sessionId }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tableData, setTableData] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(prompt.currentPage || 1);
  const [rowsPerPage, setRowsPerPage] = useState(prompt.rowsPerPage || 10);
  const totalRows = prompt.totalRows || 0;
  console.log("Current Page:", currentPage, "Rows Per Page:", rowsPerPage);

  const { navType } = uiStore.config;
  const theme = useTheme();
  const location = useLocation();
  const { title, endpoint } = location.state || {};
  console.log(prompt,"prompt props,,,,,,");
  console.log(tableData,"prompt table data in data visualization");
  
  console.log(sessionId,'session id datavisualizatipon');
  
  let sql = prompt.sql; // Assuming sql comes from prompt or define your SQL query
  console.log(sql,"check sql"); 

  useEffect(() => {
    setTableData(prompt.tableData); // Initialize with prompt data if available
    console.log(tableData,"prompt table data usefect");
    
  }, [prompt.sqlQuery, sql]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        
        console.log("Fetching data with page: uuuuu", currentPage, "and limituuuu:", rowsPerPage);


        const response = sql && sql.trim() !== '' 
        ? await api.fetchData_History(currentPage,sql,rowsPerPage, prompt.uuid, appId) 
        : await api.fetchData(currentPage, rowsPerPage, prompt.query, sessionId, prompt.uuid, endpoint, appId);
      
      console.log(sql, "check sql");
      

        // Check if the response is valid before setting the data
        if (response && response.response) {
          setTableData(response.response);
        } else {
          console.error("No response data received");
        }
      } catch (error) {
        console.error("Failed to fetch data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, rowsPerPage]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handlePageChange = (newPage) => {
    console.log("New page:", newPage);  // newPage should be a valid number
    setCurrentPage(newPage + 1);  // DataGrid uses 0-based index, so add 1 for 1-based page numbers
  };
  

    const handlePageSizeChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
    setCurrentPage(1);
  };

  // Define columns for DataGrid
  const columns = [
    { field: "S.No", headerName: "S.No", width: 100 },
    ...Object.keys(tableData[0] || {})
      .filter((key) => key !== "S.No")
      .map((key) => ({
        field: key,
        headerName: key,
        width: 180,
      })),
  ];

  if (prompt.error) {
    return (
      <Typography color="error" variant="body1">
        {prompt.error}
      </Typography>
    );
  }

  if (!prompt.isRendered) return null;

  return (
    <Grid container spacing={2} mb={3}>
      <Grid item xs={12}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          center
          sx={{ height: "40px",
           }}
        >
          <Tab
            icon={<TableChartIcon />}
            label="Data"
            sx={{
              padding: 0,
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          />
          <Tab
            icon={<BarChartIcon />}
            label="Charts"
            sx={{
              padding: 0,
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          />
        </Tabs>

        <Box mt={2}>
          {activeTab === 0 ? (
            <SubCard>
              <div style={{ height: 600, width: "100%" }}>
                <DataGrid
                  rows={tableData.length > 0 ? tableData : prompt.tableData}
                  columns={columns}
                  getRowId={(row) => row["S.No"]}
                  pagination
                  pageSize={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 20, 30, 50]}
                  rowCount={totalRows}
                  paginationMode="server"
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  page={currentPage - 1}
                  loading={loading}
                  sx={{
                    '& .MuiDataGrid-columnHeader:focus': {
                      outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeader:focus-within': {
                      outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeader--sortable:focus': {
                      outline: 'none',
                    },
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                  }}
                />
              </div>
            </SubCard>
          ) : (
            <SubCard>
              {prompt.iframeKey ? (
                <iframe
                  key={prompt.iframeKey}                
                  src={`${process.env.REACT_APP_DASHBOARD_BASE_URL}/${appId}/${prompt.uuid}?theme=${navType}`}
                  style={{ width: "100%", height: "580px", border: "none" }}
                  title={`Graph-${prompt.uuid}`}
                />
              ) : (
                <Typography variant="body1" align="center">
                  No graph available for this query
                </Typography>
              )}
            </SubCard>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default DataVisualization;
