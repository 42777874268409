import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { Skeleton } from "@mui/material";
import {
  Box,
  Card,
  CardHeader,
  Grid,
  Typography,
  Badge,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Slider from "react-slick";

import api from "service/api";
import VerticalCard from "shared-ui/ui-component/cards/VerticalCard";
import SmallCards from "shared-ui/ui-component/cards/SmallCards";
import { gridSpacing } from "shared-ui/store/constant";
import { uiStore } from "shared-ui/store";
import LargeCards from "shared-ui/ui-component/cards/LargeCards";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MediumCards from "shared-ui/ui-component/cards/MediumCards";
import CardContentHeader from "shared-ui/ui-component/cards/CardContentHeader";
import LeftImageCard from "shared-ui/ui-component/cards/LeftImageCard";
import AddNewForm from "../popup/addNew";
import ConfirmDeleteModal from "shared-ui/ui-component/ConfirmDeleteModal";
import EditAppModal from "views/EditAppModal";
import mongoService from "service/mongoService";
import { height } from "@mui/system";

const HomeDash = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { navType } = uiStore.config;

  const settings = {
    // rtl:false,
    autoplay: true,
    arrows: true,
    dots: false,
    infinite: false,
    speed: 700,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 658,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2.75,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 3.75,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1688,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 2490,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const settingsTwo = {
    autoplay: false,
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2090,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slideThree = {
    autoplay: false,
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1256,
        settings: {
          slidesToShow: 2.25,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 658,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2090,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsDataFlow = {
    autoplay: false,
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1256,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1535,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1980,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2760,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3560,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1.5,
        },
      },
    ],
  };

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState({});
  const handleOpenModal = () => setOpenModal(true);
  const handleCancel = () => setOpenModal(false);

  const [open, setOpen] = useState(false);
  const [appIdToDelete, setAppIdToDelete] = useState(null);

  const [appDataToEdit, setAppDataToEdit] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [mongo, setMongo] = useState("");

  const [recentApps, setrecentApps] = useState([]);

  const [dashboardData, setDashboardData] = useState([]);

  const [appData, setAppData] = useState([]);

  const fetchData = async () => {
    try {
      const result = await api.getData();
      setData(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const response = await mongoService.getDocuments("dashboard", 1, 200);
      setDashboardData(response);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAppData = async () => {
    try {
      setLoading(true);
      const response = await mongoService.getDocuments(
        "apps",
        1,
        200,
        "created_time",
        "desc"
      );
      if (response.items && Array.isArray(response.items)) {
        const sortedItems = response.items.sort((a, b) => {
          return new Date(b.created_time) - new Date(a.created_time);
        });

        setAppData({ ...response, items: sortedItems });
      } else {
        console.warn("Items array is empty or undefined");
        setAppData({ ...response, items: [] });
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRecentAppData = async () => {
    try {
      setLoading(true);
      setrecentApps([]);
      const response = await mongoService.getDocuments(
        "recently_opened_Apps",
        1,
        10,
        "last_opened",
        "desc"
      );

      if (response.items && Array.isArray(response.items)) {
        const seenTitles = new Set();
        const uniqueApps = [];

        response.items.forEach((app) => {
          if (!seenTitles.has(app.title)) {
            seenTitles.add(app.title);
            uniqueApps.push(app);
          }
        });

        setrecentApps(uniqueApps);
      } else {
        console.warn("Items array is empty or undefined");
        setrecentApps([]);
      }
    } catch (error) {
      console.error("Error fetching recent app data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRecentAppData();
  }, []);

  useEffect(() => {
    fetchAppData();
    fetchDashboardData();
  }, []);

  const handleAppClick = async (tile) => {
    add_to_recents(tile);
    const id_fetch = tile.app_id ? tile.app_id : tile._id;
    navigate(`/apps/${id_fetch}/`, { state: { appId: id_fetch } });
  };

  const add_to_recents = async (tile) => {
    const id_fetch = tile.app_id ? tile.app_id : tile._id;

    const data = {
      ...tile,
      last_opened: new Date().toISOString(),
      app_id: id_fetch,
    };
    delete data._id;

    try {
      await mongoService.createDocument("recently_opened_Apps", data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenEditModal = async (_id) => {
    try {
      const appData = await api.getAppDetails(_id);
      setAppDataToEdit(appData);
      setOpenEditModal(true);
    } catch (error) {
      console.error("Error fetching app details:", error);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setAppDataToEdit(null);
  };

  const handleUpdateApp = async (updatedData) => {
    fetchData();
    fetchDashboardData();
    fetchAppData();
    handleCloseEditModal();
  };

  const handleOpenDeleteModal = (_id) => {
    setAppIdToDelete(_id);
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
    setAppIdToDelete(null);
  };

  const handleDelete = async () => {
    if (appIdToDelete) {
      try {
        await api.deleteApp(appIdToDelete);
        console.log(`App with ID ${appIdToDelete} deleted successfully`);
        fetchData();
        fetchDashboardData();
        fetchAppData();
      } catch (error) {
        console.error("Error deleting app:", error);
      }
      handleCloseDeleteModal();
    }
  };

  //workflow
  const group3Data = dashboardData?.items?.find((item) => item.group_id === 3);

  //dataset
  const group1Data = dashboardData?.items?.find((item) => item.group_id === 1);

  //dataaNALYSIS
  const group2Data = dashboardData?.items?.find((item) => item.group_id === 2);

  //Future engineering
  const group4Data = dashboardData?.items?.find((item) => item.group_id === 4);

  // Recent
  const group5Data = dashboardData?.items?.find((item) => item.group_id === 5);

  const appDataGroup4 =
    appData?.items?.filter((item) => item.group_id === 4) || [];

  const appDataGroup2 =
    appData?.items?.filter((item) => item.group_id === 2) || [];

  const appDataGroup3 =
    appData?.items?.filter((item) => item.group_id === 3) || [];

  const appDataGroup1 =
    appData?.items?.filter((item) => item.group_id === 1) || [];

  const imageUrl = `${process.env.REACT_APP_IMAGE_DASH_URL}`;

  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item mt={0.25} xs={12}>
          <Card
            className="sliderSmall"
            sx={{
              mb: 2,
              background: theme.palette.background.default,
              border: "1px solid",
              borderColor: theme.palette.background.border,
            }}
          >
            <Typography variant="cardHeader" p={2}>
              {group5Data?.name}
            </Typography>

            <div>
              {recentApps?.length > 0 ? (
                <Slider {...settings}>
                  {recentApps?.map((tile, tileIndex) => (
                    <SmallCards
                      key={tileIndex}
                      onHandleClick={() => handleAppClick(tile)} // Use _id if _id is not available
                      imageSrc={`${imageUrl}${
                        navType === "light"
                          ? tile.background_image
                          : tile.background_image_dark
                      }`}
                      title={tile.title}
                      description={tile.description}
                      tags={tile.tags}
                      onHandleDelete={() => handleOpenDeleteModal(tile._id)} // Use _id here
                      onHandleEdit={() => handleOpenEditModal(tile._id)} // Use _id here
                    />
                  ))}
                </Slider>
              ) : (
                <Grid container spacing={2} px={2} pb={2} mt={1}>
                  {[...Array(6)].map((_, index) => (
                    <Grid
                      item
                      xs={2}
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={200}
                        height={120}
                      />
                      <Skeleton variant="text" width="60%" />
                      <Skeleton variant="text" width="80%" />
                      <Skeleton variant="text" width="40%" />
                    </Grid>
                  ))}
                </Grid>
              )}
            </div>

            <ConfirmDeleteModal
              open={open}
              onClose={handleCloseDeleteModal}
              onConfirm={handleDelete}
            />
            <EditAppModal
              open={openEditModal}
              onClose={handleCloseEditModal}
              appData={appDataToEdit}
              onUpdate={handleUpdateApp}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2.75}>
            <Grid item lg={4} md={12} xs={12} xl={3}>
              <Card
                className="sliderDivMain"
                sx={{
                  mb: 2,
                  background: theme.palette.background.default,
                  border: "1px solid",
                  borderColor: theme.palette.background.border,
                }}
              >
                <Typography variant="cardHeader" p={2}>
                  {group1Data?.name}
                </Typography>

                {appDataGroup1.length > 0 ? (
                  appDataGroup1.slice(-1).map((tile, tileIndex) => (
                    <VerticalCard
                      key={tileIndex}
                      onHandleClick={() => handleAppClick(tile)} // Use _id if _id is not available
                      imageSrc={`${imageUrl}${
                        navType === "light"
                          ? tile.background_image
                          : tile.background_image_dark
                      }`}
                      title={tile.title}
                      description={tile.description}
                      tags={tile.tags}
                      onHandleDelete={() => handleOpenDeleteModal(tile._id)} // Use _id here
                      onHandleEdit={() => handleOpenEditModal(tile._id)} // Use _id here
                      onViewMoreClick={() =>
                        navigate(`/apps/Dataset/${tile.group_id}`, {
                          state: {
                            groupTitle: group1Data.name, // Make sure groupData is defined
                          },
                        })
                      }
                    />
                  ))
                ) : (
                  <Box width={300} padding={2}>
                    <Skeleton variant="rectangular" width="100%" height={250} />
                    <Box mt={1}>
                      <Skeleton variant="text" width="70%" />
                      <Skeleton variant="text" width="90%" />
                      <Skeleton variant="text" width="50%" />
                      <Skeleton variant="rounded" width="100px" height={30} />
                    </Box>
                  </Box>
                )}
              </Card>
              <ConfirmDeleteModal
                open={open}
                onClose={handleCloseDeleteModal}
                onConfirm={handleDelete}
              />
              <EditAppModal
                open={openEditModal}
                onClose={handleCloseEditModal}
                appData={appDataToEdit}
                onUpdate={handleUpdateApp}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={8} xl={9}>
              <Card
                className="sliderDivMain"
                sx={{
                  mb: 2,
                  background: theme.palette.background.default,
                  border: "1px solid",
                  borderColor: theme.palette.background.border,
                }}
              >
                {group2Data ? (
                  <CardContentHeader
                    title={group2Data.name}
                    description={group2Data.description}
                    onAddClick={handleOpenModal}
                    onViewClick={() =>
                      navigate(
                        `/apps/${group2Data.name}/${group2Data.group_id}`,
                        {
                          state: {
                            groupTitle: group2Data.name,
                            desc: group2Data.description,
                          },
                        }
                      )
                    } // Pass data to ViewMorePage
                  />
                ) : (
                  <Grid container spacing={2} mb={2} px={2}>
                    <Grid item xs={10}>
                      <Skeleton variant="text" width="60%" height={60} />
                      <Skeleton
                        variant="text"
                        width="80%"
                        height={20}
                        style={{ marginTop: 8 }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Skeleton
                        variant="rounded"
                        width={100}
                        height={36}
                        style={{ marginTop: 16 }}
                      />
                    </Grid>
                  </Grid>
                )}

                <div>
                  {appDataGroup2 && appDataGroup2.length > 0 ? (
                    <Slider {...settingsTwo}>
                      {/* Map through the apps of the current group */}
                      {appDataGroup2
                        .reverse()
                        .slice(-5)
                        .map((tile, tileIndex) => (
                          <LargeCards
                            key={tileIndex}
                            onHandleClick={() => handleAppClick(tile)} // Use _id if _id is not available
                            imageSrc={`${imageUrl}${
                              navType === "light"
                                ? tile.background_image
                                : tile.background_image_dark
                            }`}
                            title={tile.title}
                            description={tile.description}
                            tags={tile.tags}
                            onHandleDelete={() =>
                              handleOpenDeleteModal(tile._id)
                            }
                            onHandleEdit={() => handleOpenEditModal(tile._id)} // Use _id here
                          />
                        ))}
                      <ConfirmDeleteModal
                        open={open}
                        onClose={handleCloseDeleteModal}
                        onConfirm={handleDelete}
                      />
                      <EditAppModal
                        open={openEditModal}
                        onClose={handleCloseEditModal}
                        appData={appDataToEdit}
                        onUpdate={handleUpdateApp}
                      />
                    </Slider>
                  ) : (
                    // Skeleton Loader outside the Slider
                    <Grid container spacing={2} px={2} pb={2}>
                      {[...Array(2)].map((_, index) => (
                        <Grid
                          item
                          xs={6}
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={200}
                            height={220}
                          />
                          <Skeleton variant="text" width="60%" />
                          <Skeleton variant="text" width="80%" />
                          <Skeleton variant="text" width="40%" />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </div>
              </Card>
              {/* <AddNewForm open={openModal} onClose={handleCancel} /> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card
            className="sliderDivMain"
            sx={{
              mb: 2,
              background: theme.palette.background.default,
              border: "1px solid",
              borderColor: theme.palette.background.border,
            }}
          >
            {group4Data ? (
              <CardContentHeader
                title={group4Data.name}
                description={group4Data.description}
                onAddClick={handleOpenModal}
                onViewClick={() =>
                  navigate(`/apps/${group4Data.name}/${group4Data.group_id}`, {
                    state: {
                      groupTitle: group4Data.name,
                      apps: appDataGroup4, // Pass an empty array if apps are undefined
                    },
                  })
                } // Pass data to ViewMorePage
              />
            ) : (
              <Grid container spacing={2} mb={2} px={2}>
                <Grid item xs={10}>
                  <Skeleton variant="text" width="60%" height={30} />
                  <Skeleton
                    variant="text"
                    width="80%"
                    height={20}
                    style={{ marginTop: 8 }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Skeleton
                    variant="rounded"
                    width={100}
                    height={36}
                    style={{ marginTop: 16 }}
                  />
                </Grid>
              </Grid>
            )}

            <div>
              {appDataGroup4 && appDataGroup4.length > 0 ? (
                <Slider {...slideThree}>
                  {appDataGroup4
                    .reverse()
                    .slice(-5)
                    .map((tile, tileIndex) => (
                      <MediumCards
                        key={tileIndex}
                        onHandleClick={() => handleAppClick(tile)}
                        imageSrc={`${imageUrl}${
                          navType === "light"
                            ? tile.background_image
                            : tile.background_image_dark
                        }`}
                        title={tile.title}
                        description={tile.description}
                        tags={tile.tags}
                        onHandleDelete={() => handleOpenDeleteModal(tile._id)}
                        onHandleEdit={() => handleOpenEditModal(tile._id)}
                      />
                    ))}
                </Slider>
              ) : (
                // Skeleton Loader outside the Slider
                <Grid container display="flex" spacing={3} padding={2}>
                  {[...Array(3)].map((_, index) => (
                    <Grid item xs={4} key={index}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={150}
                      />
                      <Skeleton
                        variant="text"
                        width="75%"
                        height={20}
                        style={{ marginTop: 8 }}
                      />
                      <Skeleton
                        variant="text"
                        width="60%"
                        height={16}
                        style={{ marginTop: 4 }}
                      />
                      <Skeleton
                        variant="text"
                        width="40%"
                        height={14}
                        style={{ marginTop: 4 }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </div>

            <ConfirmDeleteModal
              open={open}
              onClose={handleCloseDeleteModal}
              onConfirm={handleDelete}
            />
            <EditAppModal
              open={openEditModal}
              onClose={handleCloseEditModal}
              appData={appDataToEdit}
              onUpdate={handleUpdateApp}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card
            className="sliderDivMain"
            sx={{
              // mb: 2,
              background: theme.palette.background.default,
              border: "1px solid",
              borderColor: theme.palette.background.border,
            }}
          >
            <Grid container spacing={gridSpacing} alignItems="center">
              <Grid item xs={12}>
                {group3Data ? (
                  <CardContentHeader
                    title={group3Data.name}
                    description={group3Data.description}
                    onAddClick={handleOpenModal}
                    onViewClick={() =>
                      navigate(
                        `/apps/${group3Data.name}/${group3Data.group_id}`,
                        {
                          state: {
                            groupTitle: group3Data.name,
                            apps: appDataGroup3, // Pass an empty array if apps are undefined
                          },
                        }
                      )
                    } // Pass data to ViewMorePage
                  />
                ) : (
                  <Grid container spacing={2} mb={2} px={2}>
                    <Grid item xs={11}>
                      <Skeleton variant="text" width="60%" height={30} />
                      <Skeleton
                        variant="text"
                        width="80%"
                        height={20}
                        style={{ marginTop: 8 }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Skeleton
                        variant="rounded"
                        width={100}
                        height={36}
                        style={{ marginTop: 16 }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={gridSpacing} mx={0.5} alignItems="center">
              <Grid item xs={12} md={12} lg={12} xl={6} mt={2.5}>
                <Grid container spacing={2}>
                  {appDataGroup3.length > 0 ? (
                    appDataGroup3
                      .slice(-4) // Get the last 4 items
                      .reverse() // Reverse the order
                      .map((tile, tileIndex) => (
                        <Grid item md={12} lg={6} xs={12} key={tileIndex}>
                          <LeftImageCard
                            onHandleClick={() => handleAppClick(tile)} // Use _id if _id is not available
                            imageSrc={`${imageUrl}${
                              navType === "light"
                                ? tile.background_image
                                : tile.background_image_dark
                            }`}
                            title={tile.title}
                            description={tile.description}
                            tags={tile.tags}
                            onHandleDelete={() =>
                              handleOpenDeleteModal(tile._id)
                            }
                            onHandleEdit={() => handleOpenEditModal(tile._id)} // Open edit modal
                          />
                        </Grid>
                      ))
                  ) : (
                    <Grid container spacing={2} px={3}>
                      {[...Array(4)].map((_, index) => (
                        <Grid item md={6} xs={6} key={index}>
                          <Box>
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={70}
                            />
                            <Skeleton
                              variant="text"
                              width="80%"
                              height={20}
                              style={{ marginTop: 8 }}
                            />
                            <Skeleton
                              variant="text"
                              width="60%"
                              height={16}
                              style={{ marginTop: 4 }}
                            />
                            <Skeleton
                              variant="text"
                              width="40%"
                              height={14}
                              style={{ marginTop: 4 }}
                            />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} lg={12} xl={6}>
                {appDataGroup3 && appDataGroup3.length > 0 ? (
                  <Slider {...settingsDataFlow}>
                    {/* Map through the apps of the current group */}
                    {appDataGroup3
                      .reverse()
                      .slice(-5)
                      .map((tile, tileIndex) => (
                        <MediumCards
                          key={tileIndex}
                          onHandleClick={() => handleAppClick(tile)} // Use _id if _id is not available
                          imageSrc={`${imageUrl}${
                            navType === "light"
                              ? tile.background_image
                              : tile.background_image_dark
                          }`}
                          title={tile.title}
                          description={tile.description}
                          tags={tile.tags}
                          onHandleDelete={() => handleOpenDeleteModal(tile._id)}
                          onHandleEdit={() => handleOpenEditModal(tile._id)} // Use _id here
                        />
                      ))}
                    <ConfirmDeleteModal
                      open={open}
                      onClose={handleCloseDeleteModal}
                      onConfirm={handleDelete}
                    />
                    <EditAppModal
                      open={openEditModal}
                      onClose={handleCloseEditModal}
                      appData={appDataToEdit}
                      onUpdate={handleUpdateApp}
                    />
                  </Slider>
                ) : (
                  // Skeleton Loader outside the Slider
                  <Grid container spacing={2} px={2} pb={2}>
                    {[...Array(2)].map((_, index) => (
                      <Grid
                        item
                        xs={6}
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={300}
                          height={220}
                        />
                        <Skeleton variant="text" width="60%" />
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="40%" />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default HomeDash;
