import axios from 'axios';

const baseURL = `${process.env.REACT_APP_MONGO_API_URL || 'http://localhost:7015'}`;
// const baseURL = `http://localhost:7015`;


// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: baseURL,
});

// Add a request interceptor to include the token in headers
axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('serviceToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const logError = (error) => {
    if (error.response) {
        console.log('Server Response Error:', error.response.data);
        console.log('Response Status:', error.response.status);
        console.log('Response Headers:', error.response.headers);
    } else if (error.request) {
        console.log('Request Error:', error.request);
    } else {
        console.log('General Error:', error.message);
    }
};

const mongoService = {
    // Create document
    async createDocument(collection, document) {
        const url = `${baseURL}/${collection}`;
        try {
            const response = await axiosInstance.post(url, document);
            return response.data;
        } catch (error) {
            logError(error);
            throw error;
        }
    },

    // Get all documents with pagination and sorting
    async getDocuments(collection, page = 1, limit = 10, sortField = '_id', sortOrder = 'asc', filters = {}) {
        let queryParams = `page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}`;
        for (const [key, value] of Object.entries(filters)) {
            queryParams += `&${key}=${value}`;
        }
        const url = `${baseURL}/${collection}?${queryParams}`;
        try {
            const response = await axiosInstance.get(url);
            return response.data;
        } catch (error) {
            logError(error);
            throw error;
        }
    },

    // Get single document by ID
    async getDocument(collection, id) {
        const url = `${baseURL}/${collection}/${id}`;
        try {
            const response = await axiosInstance.get(url);
            return response.data;
        } catch (error) {
            logError(error);
            throw error;
        }
    },

    // Update document by ID
    async updateDocument(collection, id, updates) {
        const url = `${baseURL}/${collection}/${id}`;
        try {
            const response = await axiosInstance.put(url, updates);
            return response.data;
        } catch (error) {
            logError(error);
            throw error;
        }
    },

    // Delete document by ID
    async deleteDocument(collection, id) {
        const url = `${baseURL}/${collection}/${id}`;
        try {
            const response = await axiosInstance.delete(url);
            return response.data;
        } catch (error) {
            logError(error);
            throw error;
        }
    }
};

export default mongoService;
