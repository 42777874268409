// AddAppPage.js

import React, { useState, useEffect } from "react";
import Skeleton from '@mui/material/Skeleton';
import { uiStore } from 'shared-ui/store';  

const AddAppPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { navType } = uiStore.config;

  const baseURL = process.env.REACT_APP_STREAMLIT_URL; // Use environment variable for base URL
  const url = `${baseURL}?appid=${process.env.REACT_APP_CREATE_APP_ID}&theme=${navType}`; // Construct full URL for AddApp page
 
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  const handleLoadError = () => {
    setHasError(true);
    setIsLoading(false);
  };

  return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {url ? (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          {isLoading && (
            <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </div>
          )}
          {!isLoading && !hasError && (
            <object
              data={url}
              type="text/html"
              width="100%"
              height="100%"
              style={{ border: 'none' }}
              onLoad={() => setIsLoading(false)}
              onError={handleLoadError}
            />
          )}
          {hasError && <p>Error loading the AddApp page. Please try again.</p>}
        </div>
      ) : (
        <p>No URL provided</p>
      )}
    </div>
  );
};

export default AddAppPage;
