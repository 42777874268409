import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button, Tooltip } from "@mui/material";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

const MediumCards = ({ onHandleClick, imageSrc, title, description, tags ,onHandleEdit, onDelete}) => {
 
  const [dots,setDots] = useState(false)

  const handleDots=()=>{
    setDots(!dots)
  }

  return (
    <div className="container" onClick={onHandleClick}>
      <div className="mediumCard">
        <div className="imgBx" >
          <img src={imageSrc} alt={`${title} Image`} />
        </div>
        <Box className="content">
          <Box display="flex" alignItems="center" width="100%">
            <Typography variant="h6" className="cardTextH">
              {title}
            </Typography>
            <KeyboardArrowRightIcon className="iconCard" />
          </Box>
          <Typography variant="cardDesc" className="cardDesc" mt={1} mb={2}>
            {description}
          </Typography>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            {tags &&
              tags.map((tag, index) => (
                <span key={index} className="badge">
                  {tag}
                </span>
              ))}
          </Box>
          {/* <Box className="lastVisitDiv">
            <Box>
              <Typography>Last Visited time:</Typography>
            </Box>
           
          </Box> */}
          {/* <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              className="editDiv"
            >
              <MoreVertIcon onClick={handleDots} />
           
              <Box className="editBtnsGroup">
              <Tooltip title="View" placement="right">
                <Button>
                  <VisibilityIcon onClick={onHandleClick} />
                </Button>
              </Tooltip>
              <Tooltip title="Edit" placement="right">
                <Button onClick={(event) => {
                      event.stopPropagation();
                      onHandleEdit();
                    }}
                    >
                  <ModeEditIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Delete" placement="right"   
              onClick={(event) => {
                      event.stopPropagation();
                      onHandleDelete();
                    }}>
                <Button>
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Box>
          
            </Box> */}
        </Box>
       
       

      </div>
    </div>
  );
};

// Define prop types for better usability
MediumCards.propTypes = {
  onHandleClick: PropTypes.func,
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
};



export default MediumCards;
