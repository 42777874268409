import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button, Tooltip } from "@mui/material";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

const SmallCards = ({ onHandleClick, imageSrc, title, description, tags, onHandleEdit, onHandleDelete }) => {
  const [dots, setDots] = useState(false);

  const handleDots = () => {
    setDots(!dots);
  };

  // State to store the last visit time
  const [lastVisitTime, setLastVisitTime] = useState(null);

  // Load last visit time from localStorage when the component mounts
  useEffect(() => {
    const savedTime = localStorage.getItem("lastVisitTime");
    if (savedTime) {
      setLastVisitTime(new Date(savedTime));
    }
  }, []);

  // Function to handle card click
  const handleClick = () => {
    const currentTime = new Date();
    setLastVisitTime(currentTime);

    // Save the current time to localStorage
    localStorage.setItem("lastVisitTime", currentTime);
  };

  return (
    <div className="container" onClick={onHandleClick}>
      <div className="smallCard">
        <div className="imgBx">
          <img src={imageSrc} alt={`${title} Image`} />
        </div>
        <Box className="content">
          <Box
            display="flex"
            alignItems="center"
            width="100%"
          >
            <Typography variant="h6" className="cardTextH">
              {title}
            </Typography>
            <KeyboardArrowRightIcon className="iconCard" />
          </Box>
          <Typography variant="cardDesc" className="cardDesc" mt={1} mb={2}>
            {description}
          </Typography>

          <Box display="flex" alignItems="center" flexWrap="wrap">
            {tags &&
              tags.slice(-3).map((tag, index) => (
                <span key={index} className="badge">
                  {tag}
                </span>
              ))}
          </Box>

          {/* <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            className="editDiv"
          >
            <MoreVertIcon onClick={handleDots} />

            <Box className="editBtnsGroup">
              <Tooltip title="View" placement="right">
                <Button>
                  <VisibilityIcon onClick={onHandleClick} />
                </Button>
              </Tooltip>
              <Tooltip title="Edit" placement="right" >
                <Button
                 onClick={(event) => {
                  event.stopPropagation();
                  onHandleEdit();
                }}
                >
                  <ModeEditIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Delete" placement="right" 
              onClick={(event) => {
                event.stopPropagation();
                onHandleDelete();
              }}>
                <Button>
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Box>
          </Box> */}
          
        </Box>
      </div>
    </div>
  );
};

// Define prop types for better usability
SmallCards.propTypes = {
  onHandleClick: PropTypes.func,
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  onHandleEdit: PropTypes.func,
  onHandleDelete: PropTypes.func.isRequired, // Mark as required
};



export default SmallCards;
