import { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  Divider,
  Grid,
  Icon,
  TextField,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { useParams } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import {
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
// import { Box } from "@mui/system";
import React from "react";
// import { IconAdjustmentsHorizontal } from "@tabler/icons";

import { styled, useTheme } from "@mui/material/styles";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import BackupIcon from "@mui/icons-material/Backup";
import HistoryIcon from "@mui/icons-material/History";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { IconAdjustmentsHorizontal } from "@tabler/icons";

// import DashboardNew from "./AiGenCard/DashboardNew.js"
import MultiFileUploader from "shared-ui/ui-component/Multifileuploader";
import SubCard from "shared-ui/ui-component/cards/SubCard";
import { gridSpacing } from "shared-ui/store/constant";

// import { v4 as uuidv4 } from "uuid";
import api from "service/api";
// import ChatInterface from "./ChatInterface";
import DataVisualization from "./AiGenCard/DataVisualization";
import InputPromptQuery from "./AiGenCard/InputPromptQuery";

import SettingsIcon from "@mui/icons-material/Settings";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import Tooltip from "@mui/material/Tooltip";
import { useLocation } from "react-router-dom";
import { uiStore } from "shared-ui/store";
import { width } from "@mui/system";
import HistorySection from "./HistorySection";
import HistoryDataVisualization from "./AiGenCard/HistoryDataVisualization";

const Dashboard = () => {
  const theme = useTheme();

  const { appId } = useParams(); // Access the app_id from the URL

  const location = useLocation();
  const { title, endpoint } = location.state || {}; // If state is undefined, destructure safely

  console.log(title, endpoint, "state passed");

  const [activeTab, setActiveTab] = useState(0);
  // const [loading, setLoading] = useState(false);

  console.log(appId, "appId in dashboard");

  const [toggle, setToggle] = useState(false);

  const [chatUIQuery, setChatUIQuery] = useState("");
  console.log(chatUIQuery, "chatquery");

  const [prompts, setPrompts] = useState([]);
  const [newprompts, setNewPrompts] = useState([]);

  console.log(prompts, "prompts main");

  const [sessionId, setSessionId] = useState(uuidv4());

  console.log(sessionId, "sessionId heere dash");

  const [loading, setLoading] = useState(false);
  console.log(loading, "loading true");

  const [hide, setHide] = useState(false);

  const [historyHide, setHistoryHide] = useState(false);

  const [scroll, setScroll] = useState(false);
  console.log(scroll, "scroll value");

  const [firstQuery, setFirstQuery] = useState(""); // For single input prompt

  const [questions, setQuestions] = useState([]);
  console.log(questions, "questions array");

  const [selectedQueries, setSelectedQueries] = useState([]); // To store selected queries
  console.log(selectedQueries, "selected quries");

  const [showScrollDownBtn, setShowScrollDownBtn] = useState();
  const [showScrollUpBtn, setShowScrollUpBtn] = useState(false);

  const [questionsList, setQuestionsList] = useState([]);
  console.log(questionsList, "questionlist");

  const [historyCheck, setHistoryCheck] = useState(false);
  const [queryHistory, setQueryHistory] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [queryId, setQueryId] = useState("");
  const [sqlUpdate, setSqlUpdate] = useState("");
  console.log(sqlUpdate, "prompt sql update");

  console.log(queryId, "prompt query update");
  const [countHistory, setCountHistory] = useState();

  console.log(historyData, "history data");

  useEffect(() => {
    if (questions.length === 1) {
      setSelectedQueries([questions[0]]); // Wrap the single question in an array
    }
  }, [questions]); // Dependency array should include 'questions'

  // // Handle changes for the input query
  const handleFirstQueryChange = (value) => {
    setFirstQuery(value);
  };
  const handleFirstQueryChangeHistory = (value) => {
    setQueryHistory(value);
  };

  // Handle changes for the input query and add it to the array
  const handleFirstQuestionChange = (value) => {
    const query = value;
    setChatUIQuery(query);
  };

  // Handle selection/deselection of a query
  const handleQuerySelection = (e) => {
    const query = e.target.value;
    if (e.target.checked) {
      // Add query if checked
      setSelectedQueries((prevSelected) => [...prevSelected, query]);
    } else {
      // Remove query if unchecked
      setSelectedQueries((prevSelected) =>
        prevSelected.filter((selected) => selected !== query)
      );
    }
  };

  const unSelectCheckBox = (query) => {
    setSelectedQueries((prevSelected) =>
      prevSelected.filter((selected) => selected !== query)
    );
  };
 
  const handleFirstQueryAsk = async () => {
    setQuestions([]);
    setQuestionsList((prevQ) => [...prevQ, questions]);

    for (let query of selectedQueries) {
      const newPrompt = {
        query: query,
        uuid: uuidv4(),
        tableData: [],
        sqlQuery: "",
        loading: true,
        page: 0,
        rowsPerPage: 10,
        totalRows: 0,
        isRendered: false,
        iframeKey: uuidv4(),
      };
      setLoading(true);
      try {
        const response = await api.fetchData(
          newPrompt.page + 1,
          newPrompt.rowsPerPage,
          newPrompt.query,
          sessionId,
          newPrompt.uuid,
          endpoint,
          appId
        );

        // Handle the response and update the prompt accordingly
        newPrompt.loading = false; // Stop loading once response is received

        if (response && response.response) {
          newPrompt.tableData = response.response;
          newPrompt.sqlQuery = response.sql;
          newPrompt.totalRows = response.count || 0;
          newPrompt.isRendered = true;
          newPrompt.iframeKey = uuidv4();
        } else {
          newPrompt.tableData = [];
          newPrompt.totalRows = 0;
          newPrompt.isRendered = true;
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
        newPrompt.error = "Failed to fetch data. Please try again..";
        setFirstQuery("");
        // setPrompts((prevPrompts) => [...prevPrompts, newPrompt]);
        setPrompts((prevPrompts) =>
          prevPrompts.map((prompt) => ({
            ...prompt,
            query: "", // Set query field to empty string
          }))
        );
      } finally {     
        setPrompts((prevPrompts) => [...prevPrompts, newPrompt]);
        setLoading(false);
        setFirstQuery(""); 
        setSelectedQueries([]);
        setChatUIQuery("");
        setShowScrollDownBtn(true);
        setHide(true);
      }
      unSelectCheckBox(query);
    }
  };

  const handleFirstQueryAskHistory = async () => {
    const newPrompt = {
      query: queryHistory,
      uuid: uuidv4(),
      tableData: [],
      sqlQuery: "",
      loading: true,
      page: 0,
      rowsPerPage: 10,
      totalRows: 0,
      isRendered: false,
      iframeKey: uuidv4(),
    };
    setNewPrompts(newPrompt); 
    try {
      const response = await api.fetchData(
        newPrompt.page + 1,
        newPrompt.rowsPerPage,
        newPrompt.query,
        sessionId,
        newPrompt.uuid,
        endpoint,
        appId
      );     

      if (response && response.response) {
        newPrompt.tableData = response.response;
        newPrompt.sqlQuery = response.sql;
        newPrompt.totalRows = response.count || 0;
        newPrompt.isRendered = true;
        newPrompt.iframeKey = uuidv4();
      } else {
        newPrompt.tableData = [];
        newPrompt.totalRows = 0;
        newPrompt.isRendered = true;
      }
    } catch (error) {
      newPrompt.loading = false; 
      console.error("Error fetching data:", error);
      newPrompt.error = "Failed to fetch data. Please try again..";
      setFirstQuery("");
    
      setPrompts((prevPrompts) =>
        prevPrompts.map((prompt) => ({
          ...prompt,
          query: "", // Set query field to empty string
        }))
      );
    } finally {
   
      setPrompts((prevPrompts) => [...prevPrompts, newPrompt]);
      newPrompt.loading = false; 
      setNewPrompts(newPrompt);
      setFirstQuery(""); 
      setQuestions([]);
      setSelectedQueries([]);
      setChatUIQuery("");
      setHistoryHide(true);
    }
  };
 const handleFirstQueryAskDashBoard = async () => {
  const newPrompt = {
    query: firstQuery,
    uuid: uuidv4(),
    tableData: [],
    sqlQuery: "",
    loading: true,
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    isRendered: false,
    iframeKey: uuidv4(),
  };
  //setLoading(true);
  setNewPrompts(newPrompt); // Trigger re-render with updated prompt
  try {
    const response = await api.fetchData(
      newPrompt.page + 1,
      newPrompt.rowsPerPage,
      newPrompt.query,
      sessionId,
      newPrompt.uuid,
      endpoint,
      appId
    );

   

    if (response && response.response) {
      newPrompt.tableData = response.response;
      newPrompt.sqlQuery = response.sql;
      newPrompt.totalRows = response.count || 0;
      newPrompt.isRendered = true;
      newPrompt.iframeKey = uuidv4();
    } else {
      newPrompt.tableData = [];
      newPrompt.totalRows = 0;
      newPrompt.isRendered = true;
    }
   
  } catch (error) {
    setLoading(false);
    console.error("Error fetching data:", error);
    newPrompt.error = "Failed to fetch data. Please try again..";
    setFirstQuery("");
    // setPrompts((prevPrompts) => [...prevPrompts, newPrompt]);
    setPrompts((prevPrompts) =>
      prevPrompts.map((prompt) => ({
        ...prompt,
        query: "", // Set query field to empty string
      }))
    );
  } finally {
    // newPrompt.loading = false;
     // Handle the response and update the prompt accordingly
    newPrompt.loading = false; // Stop loading once response is received
    setPrompts((prevPrompts) => [...prevPrompts, newPrompt]);
    setNewPrompts(newPrompt);
    setFirstQuery(""); // Clear the input after submission
    setQuestions([]);
    setSelectedQueries([]);
    setChatUIQuery("");
    setHide(true);
  }
};
 

  // Handle changes for dynamic query inputs
  const handleQueryChange = (index, value) => {
    const newPrompts = [...prompts];
    newPrompts[index].query = value;
    newPrompts[index].error = ""; // Reset error on new query
    setPrompts(newPrompts);
  };  

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleToggleChatBox = () => {
    setToggle(!toggle);
  };

  const handleAddPrompt = () => {
    setScroll(!scroll);
    const newPrompt = {
      query: "",
      uuid: uuidv4(),
      tableData: [],
      sqlQuery: "",
      loading: false,
      page: 0,
      rowsPerPage: 10,
      totalRows: 0,
      isRendered: false,
      iframeKey: uuidv4(),
      error: null,
    };
    setPrompts((prevPrompts) => [...prevPrompts, newPrompt]);
  };

  const scrollDivRef = useRef(null); 

  const handleQuerySubmit = async (index) => {
    const updatedPrompts = [...prompts]; // Create a copy of prompts
    const currentPrompt = updatedPrompts[index]; // Get the current prompt
    currentPrompt.loading = true; // Set loading state for the specific prompt
    updatedPrompts[index].loading = true; 
    setPrompts(updatedPrompts); // Trigger re-render with updated prompt
  
    try {
      const response = await api.fetchData(
        currentPrompt.page + 1,
        currentPrompt.rowsPerPage,
        currentPrompt.query,
        sessionId,
        currentPrompt.uuid,
        endpoint,
        appId
      );
  
      if (response && response.response) {
        currentPrompt.tableData = response.response;
        currentPrompt.sqlQuery = response.sql;
        currentPrompt.totalRows = response.count || 0;
        currentPrompt.isRendered = true;
        currentPrompt.iframeKey = uuidv4();
      } else {
        currentPrompt.tableData = [];
        currentPrompt.totalRows = 0;
        currentPrompt.isRendered = true;
      }
    } catch (error) {
      currentPrompt.error = "Failed to fetch data. Please try again.";
    } finally {
      currentPrompt.loading = false; // Stop loading for the specific prompt
      updatedPrompts[index].loading = false;
      setPrompts([...updatedPrompts]); // Update prompts array to trigger re-render
    }
  };
  

  const handleHistoryPromptsUpdate = (prompts) => {
    console.log(prompts, "prompts from history..");
    setHistoryCheck(true);
    setHistoryHide(false);

    // Ensure prompts contains the expected structure
    if (prompts && prompts.response) {     
      setHistoryData(prompts.response);
      setCountHistory(prompts.count);
      // scrollDown()
      setShowScrollDownBtn(true);

      console.log(historyData, " promptsssss history data");
      scrollToTop();
    } else {
      console.error("Invalid prompts structure:", prompts);
      setHistoryData([]); // Set to an empty array if invalid
    }
  };

  const handleSessionIdUpdate = (session_Id) => {
    setSessionId(session_Id);
    console.log("prompt Received session ID from history:", session_Id);
    // Do something with the sessionId, e.g., update state
  };

  const handleQueryUpdate = (query) => {
    setQueryHistory(query);
    console.log(queryHistory, "prompt query from history");
  };

  const handleQueryIdUpdate = (query_id) => {
    setQueryId(query_id);
    console.log(queryId, "prompt query update");
  };
  const handleSqlUpdate = (sql_update) => {
    setSqlUpdate(sql_update);
  };

  // Handle submission for chatbox refined  query
  const handleAskRefineQueries = async () => {
    setLoading(true); // Set loading to true when starting the API call
    try {
      const response = await api.detRefineData(chatUIQuery, sessionId, appId);

      // Check if the response contains refined questions
      if (response.success && response.refined_questions) {
        setQuestions(response.refined_questions); // Store refined questions in state
      }
    } catch (error) {
      console.log(error, "error on sending request");
    } finally {
      setLoading(false); // Set loading to false after the API call
    }
  };

  const scrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth", // Smooth scroll animation
    });
  };

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 150,
      behavior: "smooth", // Smooth scroll animation
    });
  };

  // Function to check if the user has scrolled to the bottom
  const handleScroll = () => {
    const scrollableHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const currentScroll = window.scrollY;

    // Check if the current scroll position is close to the bottom
    if (currentScroll >= scrollableHeight) {
      setShowScrollDownBtn(false); // Hide the scroll down button
    } else {
      setShowScrollDownBtn(true); // Show the scroll down button
    }

    // Check if the user has scrolled down enough to show the scroll to top button
    if (currentScroll > 100) {
      // You can adjust the threshold as needed
      setShowScrollUpBtn(true); // Show the scroll up button
    } else {
      setShowScrollUpBtn(false); // Hide the scroll up button
    }
  };

  // Add event listener for scroll
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const now = new Date();
  const currentTimeString = now.toLocaleTimeString();

  useEffect(() => {
    // Scroll down the page to a specific position (e.g., bottom of the newly rendered content)
    scrollDown();
  }, [scroll]); // Dependency array to run effect when prompts change loading

  useEffect(() => {
    uiStore.updateConfig("drawerOpen", false);
  }, []);

  const scrollRefChatScreen = useRef(null); // Create a reference for the box

  // Effect to scroll to the bottom when new questions are added
  useEffect(() => {
    if (scrollRefChatScreen.current) {
      scrollRefChatScreen.current.scrollTop =
        scrollRefChatScreen.current.scrollHeight;
    }
  }, [questionsList, questions]); // Trigger the effect when questionsList or questions change

  return (
    <>
      <Grid container spacing={gridSpacing}>     

        <Grid item xs={12} xl={toggle ? 9 : 12}>
          <Box
            textAlign="center"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {showScrollDownBtn && (
              <Button
                sx={{
                  background: theme.palette.background.default,
                  border: "1px solid",
                  borderColor: theme.palette.primary.main,
                  maxWidth: "10px",
                  mx: "auto",
                }}
                onClick={scrollDown}
                className="scrollDownBtn"
              >
                <KeyboardDoubleArrowDownIcon />
              </Button>
            )}
          </Box>

          <Grid
            item
            xs={12}
            md={12}
            mb={gridSpacing}
            sx={{
              overflowY: "auto",
              position: "sticky",
              top: "85px",
              zIndex: "999",
            }}
          >
            <Card
              sx={{
                background: theme.palette.background.default,
                border: "1px solid",
                borderColor: theme.palette.background.border,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                py={2}
              >
                <Typography
                  ml={0.51}
                  variant="appHead"
                  color={theme.palette.primary.main}
                >
                  {title}
                  {/* {sessionId} */}
                </Typography>
                <Typography
                  display="flex"
                  variant="chatText"
                  alignItems="center"
                >
                  <IconAdjustmentsHorizontal stroke={1.5} size="20px" />
                  Filters {/* loadidng  {loading ? "true" : "flase"}  */}
                  <Box
                    ml={1}
                    sx={{
                      color: theme.palette.primary.main,
                      "&:hover": {
                        color: theme.palette.secondary.main,
                      },
                    }}
                  >
                    <IconButton onClick={handleToggleChatBox}>
                      <AutoAwesomeOutlinedIcon
                        sx={{
                          color: theme.palette.primary.main,
                          "&:hover": {
                            color: theme.palette.secondary.main,
                          },
                        }}
                      />
                    </IconButton>
                  </Box>
                </Typography>
              </Box>
            </Card>
          </Grid>

          <div ref={scrollDivRef}>
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                color: "gray",
                display: hide ? "none" : "block",
              }}
            >
              <Card
                key={prompt.uuid}
                sx={{
                  mb: 4,
                  p: gridSpacing,
                  background: theme.palette.background.default,
                  border: "1px solid",
                  borderColor: theme.palette.background.border,
                  display: "flex",
                }}
              >           
<InputPromptQuery
  prompt={{ query: firstQuery, loading: newprompts?.loading || false }}  
  handleQueryChange={(_, value) => handleFirstQueryChange(value)}
  handleAsk={handleFirstQueryAskDashBoard}
  btnName={
    prompt?.loading ? (
      <CircularProgress sx={{ color: "#ffff" }} size={30} />
    ) : (
      "ASK"
    )
  }
  label="Enter your query"
/>
                <Box>
                  <Button
                    onClick={handleAddPrompt}
                    disabled={loading ? true : false}
                    variant="primary"
                    sx={{
                      p: 1.1,
                      minHeight: "49px",
                      mx: 1,
                      border: "1px solid",
                      borderColor: theme.palette.background.border,
                      borderColor: theme.palette.primary.main,
                      // color: theme.palette.secondary.main,
                      "&:hover": {
                        background: theme.palette.primary.main,
                        color: theme.palette.background.default,
                      },
                    }}
                  >
                    <Tooltip title="Add new task">
                      <AddOutlinedIcon
                        sx={{
                          fontSize: "2.3em",
                          fontWeight: "900",
                        }}
                      />
                    </Tooltip>
                  </Button>
                </Box>
              </Card>
            </div>
            {historyData.length > 0 && (
              <Card
                key={prompt.uuid}
                sx={{
                  display: historyHide ? "none" : "block",
                  mb: 2,
                  p: gridSpacing,
                  background: theme.palette.background.default,
                  border: "1px solid",
                  borderColor: theme.palette.background.border,
                }}
              >
                {/* <Typography> History </Typography> */}
                <DataVisualization
                  prompt={{
                    isRendered: true,
                    tableData: historyData,
                    error: null,
                    uuid: queryId,
                    iframeKey: queryId,
                    totalRows: countHistory || 0,
                    sql: sqlUpdate,
                  }}
                  index={1}
                  appId={appId}
                  // handleAskPaginated={handleAskPaginatedHistory} // Pass the history pagination handler
                />
                <Box display="flex">
                  <InputPromptQuery
                    prompt={{ query: queryHistory, loading: newprompts.loading }} 
                    handleQueryChange={(_, value) =>
                      handleFirstQueryChangeHistory(value)
                    }
                    handleAsk={handleFirstQueryAskHistory}
                    btnName={
                      loading ? (
                        <CircularProgress
                          sx={{
                            color: "#ffff",
                          }}
                          size={30}
                        />
                      ) : (
                        "Ask"
                      )
                    }
                    
                    loading={loading}
                    label="Enter your query"
                  />

                  <Button
                    onClick={handleAddPrompt}
                    disabled={loading ? true : false}
                    sx={{
                      p: 0.8,
                      mx: 1,
                      minHeight: "49px",
                      border: "1px solid",
                      borderColor: theme.palette.background.border,
                      borderColor: theme.palette.primary.main,
                      // color: theme.palette.secondary.main,
                      "&:hover": {
                        background: theme.palette.primary.main,
                        color: theme.palette.background.default,
                      },
                    }}
                  >
                    <Tooltip title="Add new task">
                      <AddOutlinedIcon
                        sx={{
                          fontSize: "2.3em",
                          fontWeight: "900",
                        }}
                      />
                    </Tooltip>
                  </Button>
                </Box>
              </Card>
            )}

            {prompts.map((prompt, index) => (
              <Card
                key={prompt.uuid}
                sx={{
                  mb: 2,
                  p: gridSpacing,
                  background: theme.palette.background.default,
                  border: "1px solid",
                  borderColor: theme.palette.background.border,
                }}
              >
                <DataVisualization
                  key={prompt.uuid}
                  prompt={prompt}
                  index={index}
                  // renderPagination={renderPagination}
                  appId={appId}
                  sessionId={sessionId}
                />

                <Grid item xs={12}> 
                <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      gap: "10px",
                    }}
                  >
    <InputPromptQuery
  prompt={{ query: prompt.query, loading: prompt.loading }}
  handleQueryChange={(_, value) => handleQueryChange(index, value)}
  handleAsk={() => handleQuerySubmit(index)}
  btnName={
    prompt.loading ? (
      <CircularProgress
        sx={{
          color: "#ffff",
        }}
        size={30}
      />
    ) : (
      "Ask"
    )
  }
  label={`Query ${index + 1}`}
/>

                    <Button
                      onClick={handleAddPrompt}
                      disabled={loading ? true : false}
                      sx={{
                        p: 1,
                        minHeight: "49px",
                        border: "1px solid",
                        borderColor: theme.palette.background.border,
                        borderColor: theme.palette.primary.main,                       
                        "&:hover": {
                          background: theme.palette.primary.main,
                          color: theme.palette.background.default,
                        },
                      }}
                    >
                      <Tooltip title="Add new task">
                        <AddOutlinedIcon
                          sx={{
                            fontSize: "2.3em",
                            fontWeight: "900",
                          }}
                        />
                      </Tooltip>
                    </Button>
                  </Box>
                    </Grid>                
              </Card>
            ))}
          </div>
        </Grid>

        {/* chatbot space./////////// */}

        <Grid
          item
          xl={3}
          position="relative"
          sx={{
            display: toggle ? "block" : "none",
            right: toggle ? "0rem" : "150rem",
            transition: ".3s",
          }}
        >
          <Card
            sx={{
              background: theme.palette.background.default,
              border: "1px solid",
              borderColor: theme.palette.background.border,
              p: 2,
              pb: 0,
              maxHeight: "87vh",
              width: "100%",
              // height: "80vh",
              position: "sticky",
              top: "85px",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "2px",
              },
              "-ms-overflow-style": "none", // IE and Edge
              "scrollbar-width": "none", // Firefox
            }}
          >
            <Typography
              my={2}
              variant="appHead"
              display="flex"
              alignItems="center"
              gap={1}
            >
              <AutoAwesomeIcon
                sx={{
                  color: theme.palette.secondary.main,
                  mx: 1,
                }}
              />
              AI Chat Bot
            </Typography>

            <Divider
              sx={{
                background: theme.palette.grey[50],
              }}
            ></Divider>

            <Box className="chatBotHeader">
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="Chat Bot Tabs"
                sx={{
                  background: theme.palette.background.default,
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <Tab
                  // sx={{
                  //   margin: "auto",
                  // }}
                  label="AI Chat"
                  icon={<TravelExploreIcon sx={{ color: "primary.main" }} />}
                  iconPosition="start"
                />
                <Tab
                  // sx={{
                  //   margin: "auto",
                  // }}
                  label="Files"
                  icon={<BackupIcon sx={{ color: "primary.main" }} />}
                  iconPosition="start"
                />
                <Tab
                  // sx={{
                  //   margin: "auto",
                  // }}
                  label="History"
                  icon={<HistoryIcon sx={{ color: "primary.main" }} />}
                  iconPosition="start"
                />
                {/* <Tab
                  sx={{
                    margin: "auto",
                  }}
                  label="Settings"
                  icon={<SettingsIcon sx={{ color: "primary.main" }} />}
                  iconPosition="start"
                /> */}
              </Tabs>
            </Box>

            {activeTab === 0 && (
              <div className="chatBotBody">
                            <Box height="87vh"

                  display="flex"
                  sx={{
                    width: "98%",
                    overflow: "auto",
                    height: "58vh",
                    position: "absolute",
                    top: "0",
                  }}
                  m={1}
                  alignItems="end"
                  gap={1}
                  justifyContent="left"
                >
                  {/* <Box
                    sx={{
                      borderRadius: "50%",
                      width: 37,
                      height: 37,
                      background: theme.palette.secondary.light,
                      textAlign: "center",
                      padding: ".451rem",
                      mt: 1,
                    }}
                  >
                    <AutoAwesomeIcon
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: 19,
                      }}
                    />
                  </Box> */}

                  <Box
                    ref={scrollRefChatScreen}
                    sx={{
                      overflowY: "auto",
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: "59vh",
                    }}
                  >
                    {questionsList.map(
                      (questionGroup, index) =>
                        questionGroup.length > 0 && ( // Only render if the questionGroup is not empty
                          <Box
                            key={index}
                            mb={4}
                            sx={{
                              borderColor: theme.palette.background.border,
                              p: 1,
                              borderRadius: 2.5,
                              borderTopLeftRadius: 0,
                            }}
                          >
                            {questionGroup.map((question, qIndex) => (
                              <Box
                                key={qIndex}
                                sx={{
                                  background: theme.palette.background.bg,
                                  p: 1,
                                  borderRadius: 2.3,
                                  borderTopLeftRadius: 0,
                                  minWidth: "220px",
                                  width: "100%",
                                  textAlign: "left",
                                  margin: ".5rem 0",
                                  borderColor: theme.palette.background.border,
                                }}
                              >
                                <label
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    value={question}
                                    checked={selectedQueries.includes(question)} // Control checkbox state
                                    onChange={handleQuerySelection}
                                  />
                                  {question} {/* Label for the checkbox */}
                                </label>
                              </Box>
                            ))}
                          </Box>
                        )
                    )}

                    {questions.map((query, index) => (
                      <Grid xs={12} mt={1}>
                        <Box
                          key={index}
                          sx={{
                            background: theme.palette.grey[100],
                            borderRadius: 2.3,
                            borderTopLeftRadius: 0,
                            minWidth: "230px",
                            width: "100%",
                            textAlign: "left",
                            margin: ".35rem 0",
                            p: 1,
                            // border: "1px solid",
                            borderColor: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="chatText">
                            <div key={index} className="wrapper">
                              <FormControlLabel
                                // className="typing-demo"
                                sx={{
                                  padding: ".35rem 1rem",
                                  width: "100%",
                                }}
                                control={
                                  <Checkbox
                                    value={query}
                                    onChange={handleQuerySelection}
                                    defaultValue={questions.length === 1} // Auto-select if there's only one question
                                    sx={{
                                      display:
                                        questions.length > 1 ? "block" : "none",
                                      fontSize: "2em",

                                      color: theme.palette.primary.main,
                                      "&.Mui-checked": {
                                        color: theme.palette.secondary.main,
                                      },
                                    }}
                                  />
                                }
                                label={query} // This is where the label is defined
                              />
                            </div>
                          </Typography>
                        </Box>
                      </Grid>
                    ))}                  
                  </Box>
                </Box>
              </div>
            )}

            {activeTab === 1 && (
              <Box height="87vh">
                <Grid container alignItems="center" overflow="hidden">
                  <Grid item xs={12} p={2} pt="10vh">
                    <MultiFileUploader
                      allowMultiple={true}
                      sessionID={sessionId}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            {activeTab === 2 && (
              <div
                className=""
                style={{
                  height: "87vh",
                }}
              >
                <HistorySection
                  appId={appId}
                  onHistoryPromptsUpdate={handleHistoryPromptsUpdate}
                  onSessionIdUpdate={handleSessionIdUpdate}
                  onHistoryQueries={handleQueryUpdate}
                  OnQueryIdUpdate={handleQueryIdUpdate}
                  OnSqlUpdate={handleSqlUpdate}
                />
              </div>
            )}

            {activeTab === 3 && (
              <Box
                sx={{
                  height: "87vh",
                }}
              ></Box>
            )}

            <div className="chatInput">
              <Grid
                item
                xs={12}
                sx={{
                  pt: 1,
                  background: theme.palette.background.default,
                  display: activeTab === 0 ? "block" : "none",
                }}
              >
                <Card
                  sx={{
                    width: "100%",
                    background: theme.palette.background.default,
                  }}
                >
                  <Divider />
                  <Box my={2}>
                    <Button
                      // variant="contained"
                      sx={{
                        display: selectedQueries.length > 0 ? "block" : "none",

                        height: "52px",
                        minHeight: "39px",
                        width: selectedQueries.length > 0 ? "100%" : "0%",
                        border: "1px solid",
                        borderColor: theme.palette.background.border,
                        background: theme.palette.primary.main,
                        color: theme.palette.background.default,
                        transition: ".5s",

                        // color: theme.palette.secondary.main,
                        "&:hover": {
                          background: theme.palette.secondary.main,
                          color: theme.palette.background.default,
                        },
                      }}
                      onClick={handleFirstQueryAsk}
                      disabled={loading}
                    >
                      <Typography color={theme.palette.background.default}>
                        {loading ? "⏳ Processing..." : "✉️ Send Question "}
                      </Typography>
                    </Button>
                  </Box>

                  <Box
                    my={2}                  
                    alignItems="end"
                    justifyContent="space-between"
                    // display={activeTab === 0 ? "flex" : "none",
                    display={selectedQueries.length > 0 ? "none" : "flex"}
                  >
                    {/* <AudioToText/> */}

                    <TextField
                      value={chatUIQuery}
                      onChange={(e) =>
                        handleFirstQuestionChange(e.target.value)
                      }
                      placeholder="Ask me anything...."
                      fullWidth
                      multiline
                      cols={30} // Initial number of columns
                      minRows={1} // Minimum number of rows when the text field is empty
                      maxRows={3}
                      sx={{
                        maxHeight: "250px", // Set max height
                        overflow: "auto", // Allow scrolling if content exceeds max height
                      }}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAskRefineQueries}
                      sx={{
                        height: "52px",
                        ml: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <ArrowUpwardIcon />
                      )}
                    </Button>           
                  </Box>
                </Card>
              </Grid>
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
