import React from "react";
import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";

const CardContentHeader = ({ title, description, onAddClick, onViewClick }) => {
  const theme = useTheme();
  const trimDescription = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, text.lastIndexOf(" ", maxLength)) + "...";
  };

  return (
    <Box
      px={.5}
      pt={.5}
      pb={1}
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box
      sx={{
        width:'85%'
      }}>
        <Typography variant="cardHeader" p={2}>
          {title}
        </Typography>
        <Typography
          variant="h6"
          mx={2}
          sx={{
            fontSize: "1.22em",
            fontWeight: "500",
            color: theme.palette.text.dark2,
            mt: 1.5,
            width: "85%",
            maxHeight:'53px',
            overflow:'hidden'
          }}
        >
          {(description = trimDescription(description, 200))}
        </Typography>
      </Box>

      <Box className="btnGroup" sx={{
        width:'15%',
        alignItems:'end'
      }}
      mx={2}>
        {/* <Button
          className="addNewBtn"
          variant="contained"
          color="primary"
          onClick={onAddClick}
        >
          Add New <AddIcon />
        </Button> */}
        <Button
          className="viewDetailsBtn"
          variant="contained"
          color="primary"
          sx={{
            color: theme.palette.primary.main,
            background: theme.palette.background.default,
          }}
          onClick={onViewClick}
        >
          <span className="textBtn">View More</span>
          <KeyboardArrowRightIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default CardContentHeader;
