import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  Box,
  Paper,
  Modal,
  CircularProgress,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import api from "../../service/api";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 1000,
  maxHeight: "90vh",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  borderRadius: theme.shape.borderRadius,
  overflow: "auto",
  padding: theme.spacing(2),
  borderRadius:'20px'
}));

const FormFieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));

const FieldLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  "& .required": {
    color: theme.palette.error.main,
    marginLeft: "2px",
  },
}));

const EditAppModal = ({ open, onClose, appData, onUpdate }) => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    group: "",
    tags: [],
    background_image: "",
    background_image_dark: "",
    lightModeImage: "",
    darkModeImage: "",
    lightModePrompt: "",
    darkModePrompt: "",
  });
  const [isGeneratingLight, setIsGeneratingLight] = useState(false);
  const [isGeneratingDark, setIsGeneratingDark] = useState(false);

  const [loader,setLoader] = useState(false);

  useEffect(() => {
    if (appData) {
      setFormData({
        ...appData,
        tags: appData.tags || [],
        description: appData.description || "", // Initialize description
      });
    }
  }, [appData, open]);

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: name === "tags" ? value.split(", ") : value,
  //   }));
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: name === "tags" ? value.split(", ") : value,
    }));
  };

  //     const handleChange = (event) => {
  //       const { name, value } = event.target;

  //       setFormData((prevState) => {
  //           const newState = {
  //               ...prevState,
  //               [name]: name === "tags" ? value.split(", ") : value,
  //           };

  //         // Update prompts based on the description
  //         if (name === "description") {
  //             if (value.trim()) {
  //                 newState.lightModePrompt = `${value.trim()} - Light mode theme, bright colors, professional and modern design`;
  //                 newState.darkModePrompt = `${value.trim()} - Dark mode theme, deep colors, elegant and sophisticated design`;
  //             } else {
  //                 // Reset prompts if description is empty
  //                 newState.lightModePrompt = "";
  //                 newState.darkModePrompt = "";
  //             }
  //         }

  //         return newState;
  //     });
  // };

  const handleGenerateImage = async (mode) => {
    const lightModeDescription =
      "Light mode theme, bright colors, professional and modern design";
    const darkModeDescription =
      "Dark mode theme, deep colors, elegant and sophisticated design";

    const prompt =
      mode === "light"
        ? lightModeDescription + formData.lightModePrompt.trim()
        : darkModeDescription + formData.darkModePrompt.trim();

    if (!prompt) {
      console.error("Please enter a prompt first");
      return;
    }

    try {
      mode === "light" ? setIsGeneratingLight(true) : setIsGeneratingDark(true);
      const response = await api.generateImage(prompt);

      if (!response || !response.image_url) {
        throw new Error("No image URL received from the server");
      }

      // Set the generated image URL back to formData
      setFormData((prevState) => ({
        ...prevState,
        [mode === "light" ? "background_image" : "background_image_dark"]:
          response.image_url,
        [mode === "light" ? "lightModeImage" : "darkModeImage"]:
          response.image_url, // Set the light/dark mode image as well
      }));
    } catch (error) {
      console.error("Failed to generate image:", error);
    } finally {
      mode === "light"
        ? setIsGeneratingLight(false)
        : setIsGeneratingDark(false);
    }
  };

  const handleUploadImage = (mode, event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Set the uploaded image URL to formData
      setFormData((prevState) => ({
        ...prevState,
        [mode === "light" ? "background_image" : "background_image_dark"]:
          reader.result,
        [mode === "light" ? "lightModeImage" : "darkModeImage"]: reader.result, // Set light/dark mode image
      }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const {
        id,
        title,
        description,
        group,
        tags,
        lightModeImage,
        darkModeImage,
      } = formData;
      const formDataToSend = new FormData();

     

      // Append main fields to FormData
      formDataToSend.append("id", id || "");
      formDataToSend.append("title", title || "");
      formDataToSend.append("description", description || "");
      formDataToSend.append("group", group || "");

      // formDataToSend.append("tags", JSON.stringify(tags || []));

      // Append tags directly, avoiding extra slashes
      tags.forEach((tag) => {
        formDataToSend.append("tags[]", tag); // Use tags[] to indicate an array
      });

      // formDataToSend.append("background_image", '');
      // formDataToSend.append("background_image_dark", '');

      // Submit main form data
      const response = await api.updateApp(id, formDataToSend);

      if (response) {

        if (formData.lightModeImage) {
          await uploadImage(formData.lightModeImage, response.id, "light");
        }
        if (formData.darkModeImage) {
          await uploadImage(formData.darkModeImage, response.id, "dark");
        }

        // Update parent component and close modal
        onUpdate(formData);
        onClose();
      }
    } catch (error) {
      console.error("Failed to update app:", error);
    }
  };

  // Separate function to handle image upload

  const uploadImage = async (image, appId, themeMode) => {
    try {
      console.log(
        `Uploading ${themeMode} mode background image for app ID:`,
        appId
      );
      const res = await fetch(image);
      const blob = await res.blob();

      const formData = new FormData();
      formData.append("image", blob, "image.jpg");

      const uploadResponse = await api.uploadBackgroundImage(
        appId,
        formData,
        themeMode
      );
      console.log(
        `Background image (${themeMode} mode) upload successful:`,
        uploadResponse
      );
    } catch (uploadError) {
      console.error(
        `Failed to upload ${themeMode} background image:`,
        uploadError
      );
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="edit-app-modal">
      <ModalContent>
        <Box padding={4}>
          <Typography variant="h4" component="h2" mb={2}>
            Edit Application
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              {/* / Inside your render method/component return */}

              <Grid item xs={12} md={6}>
                <FormFieldWrapper>
                  <FieldLabel variant="body1">
                    Application Title<span className="required">*</span>
                  </FieldLabel>
                  <TextField
                    fullWidth
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    variant="outlined"
                    required
                  />
                </FormFieldWrapper>

                <FormFieldWrapper>
                  <FieldLabel variant="body1">Description</FieldLabel>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </FormFieldWrapper>

                <FormFieldWrapper>
                  <FieldLabel variant="body1">
                    Group<span className="required">*</span>
                  </FieldLabel>
                  <Select
                    fullWidth
                    name="group"
                    value={formData.group}
                    onChange={handleChange}
                    variant="outlined"
                    required
                  >
                    <MenuItem value="Dataset">Dataset</MenuItem>
                    <MenuItem value="Analytics">Analytics</MenuItem>
                    <MenuItem value="Workflow">Workflow</MenuItem>
                  </Select>
                </FormFieldWrapper>

                <FormFieldWrapper>
                  <FieldLabel variant="body1">Tags</FieldLabel>
                  <TextField
                    fullWidth
                    name="tags"
                    value={formData.tags.join(", ")}
                    onChange={handleChange}
                    variant="outlined"
                    placeholder="Enter tags separated by commas"
                  />
                </FormFieldWrapper>

                <Grid item xs={12} md={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 4 }}
                  >
                    Save Changes
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{ mt: 4, ml: 2 }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                {/* Light Mode Uploader */}
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Light Mode Background Image
                  <span style={{ color: "red" }}>*</span>
                </Typography>

                <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                  <TextField
                    fullWidth
                    placeholder="Enter prompt to generate image"
                    name="lightModePrompt" // Use a unique name for the light mode prompt
                    value={formData.lightModePrompt} // Bind to the correct state
                    onChange={handleChange}
                    variant="outlined"
                  />
                  <Button
                    variant="contained"
                    onClick={() => handleGenerateImage("light")}
                    disabled={isGeneratingLight || !formData.lightModePrompt}
                  >
                    Generate
                  </Button>
                </Box>

                {/* Background image preview for Light Mode */}
                <Box
                  sx={{
                    width: "100%",
                    height: "155px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 2,
                    background: theme.palette.background.default,
                    border: "1px solid",
                    borderColor: theme.palette.background.border,
                    borderRadius: 2,
                    position: "relative",
                  }}
                >
                     {/* <CircularProgress/> */}

                  {formData.background_image ? (
                    <>
                     <img
                      src={formData.background_image}
                      alt="Light mode background"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                    </>
                  
                   
                  ) : (
                    <PhotoCamera
                      sx={{ fontSize: 150, color: "#bdbdbd", opacity: 0.3 }}
                    />
                  )}
                </Box>

                {/* Upload Light Mode Image */}
                <Box sx={{ mt: 2 }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleUploadImage("light", e)}
                    style={{ display: "none" }}
                    id="light-upload-button"
                  />
                  <label htmlFor="light-upload-button">
                    <Button variant="outlined" component="span" sx={{ mt: 1 }}>
                      Upload Light Mode Image
                    </Button>
                  </label>
                </Box>

                {/* Dark Mode Uploader */}
                <Typography variant="body1" sx={{ mb: 1, mt: 3 }}>
                  Dark Mode Background Image
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "165px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 2,
                    background: theme.palette.background.default,
                    border: "1px solid",
                    borderColor: theme.palette.background.border,
                    borderRadius: 2,
                    position: "relative",
                  }}
                >
                  {formData.background_image_dark ? (
                    <img
                      src={formData.background_image_dark}
                      alt="Dark mode background"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <PhotoCamera
                      sx={{ fontSize: 150, color: "#bdbdbd", opacity: 0.3 }}
                    />
                  )}
                </Box>
                <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                  <TextField
                    fullWidth
                    placeholder="Enter dark mode prompt"
                    name="darkModePrompt" // Use a unique name for the dark mode prompt
                    value={formData.darkModePrompt} // Bind to the correct state
                    onChange={handleChange}
                    variant="outlined"
                  />
                  <Button
                    variant="contained"
                    onClick={() => handleGenerateImage("dark")}
                    disabled={isGeneratingDark || !formData.darkModePrompt}
                  >
                    Generate
                  </Button>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleUploadImage("dark", e)}
                    style={{ display: "none" }}
                    id="dark-upload-button"
                  />
                  <label htmlFor="dark-upload-button">
                    <Button variant="outlined" component="span" sx={{ mt: 1 }}>
                      Upload Dark Mode Image
                    </Button>
                  </label>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default EditAppModal;
