import { useState, useEffect } from 'react';
import mongoService from 'service/mongoService';

const  useAppData = (groupName, groupId) => {
    const [appData, setAppData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchApps = async () => {
            try {
                setLoading(true);
                const response = await mongoService.getDocuments("apps", 1, 10, '_id', 'asc', { group: groupName, group_id: groupId })
                setAppData(response)
            } catch (error) {
                console.error("Error fetching apps:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchApps();
    }, [groupId, groupName]);
    return {appData, loading}

}
export default useAppData